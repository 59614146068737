/* eslint-disable no-undef */
import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // eslint-disable-next-line no-undef
  smoothScroll('a[href^="#"]', { offset: 0 })
})

$('.callout.backend .carousel').carousel({
  interval: false
})

$(document).scroll(function () {
  const scroll = $(this).scrollTop()
  if (scroll > 0) {
    $('.header').addClass('fixed')
  } else {
    $('.header').removeClass('fixed')
  }
})
